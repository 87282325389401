const scrollToElem = function(element) {
    
    const getHeaderHeight = function(element) {
        return document.querySelector('.header').clientHeight;
    }
    
    let elemCoords; 
    let top;
    
    if (!element) {
        top = 0;
    } else {
        elemCoords = element.getBoundingClientRect();
        top = elemCoords.top + pageYOffset - getHeaderHeight();
    }
    window.scrollTo({
        top: top,
        behavior: "smooth"
    });
};

document.addEventListener('click', function(event) {
    let target = event.target;
    
    if(target.closest('.scrollToID')) {
        
        event.preventDefault()
        
        let elemHref = target.closest('.scrollToID').href;
        let parseHref = elemHref.substr(elemHref.indexOf("#")+1);
        
        scrollToElem(document.querySelector(`#${parseHref}`));
    }
});


$('.block-faq__header').on('click', function () {
    $(this).toggleClass('active');
    $(this).next('.block-faq__content').slideToggle();
});

setTimeout(function(){
    const glightbox = GLightbox(); 
}, 1000);


$('[data-goal]').on('click', function() {
    let goal = $(this).data('goal');

    if(ym) {
        ym(93270592, 'reachGoal', goal);
    }
    
    if(gtag) {
        gtag('event', 'click', {'event_category': 'button', 'event_label': goal});
    }
});