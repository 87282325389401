const productSlider = new Swiper('.product-slider', {
    slidesPerView: 1,
    spaceBetween: 0,
    loop: false,
    pagination: {
        el: ".swiper-pagination",
    },
    effect: 'fade',
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    }
});

const services_slider = new Swiper('.js_services_slider', {
    slidesPerView: 1,
    spaceBetween: 0,
    loop: false,
    pagination: {
        el: ".swiper-pagination",
    },
    navigation: {
        nextEl: '.js_services_btn_right',
        prevEl: '.js_services_btn_left',
    },
    breakpoints: {
        510: {
            slidesPerView: 1.4,
            spaceBetween: 20,
        },
        640: {
            slidesPerView: 2.1,
            spaceBetween: 20,
        },
        768: {
            slidesPerView: 2.01,
            spaceBetween: 25,
        },
        992: {
            slidesPerView: 3,
            spaceBetween: 30,
        }
    }
});

const news_slider = new Swiper('.js_news_slider', {
    slidesPerView: 1,
    spaceBetween: 0,
    loop: false,
    pagination: {
        el: ".swiper-pagination",
    },
    navigation: {
        nextEl: '.js_news_btn_right',
        prevEl: '.js_news_btn_left',
    },
    breakpoints: {
        510: {
            slidesPerView: 1.4,
            spaceBetween: 20,
        },
        640: {
            slidesPerView: 2.1,
            spaceBetween: 20,
        },
        768: {
            slidesPerView: 2.01,
            spaceBetween: 25,
        },
        992: {
            slidesPerView: 3,
            spaceBetween: 30,
        }
    }
});

const products_slider = new Swiper('.js_products_slider', {
    slidesPerView: 1,
    spaceBetween: 20,
    loop: false,
    pagination: {
        el: ".swiper-pagination",
    },
    navigation: {
        nextEl: '.js_products_btn_right',
        prevEl: '.js_products_btn_left',
    },
    breakpoints: {
        510: {
            slidesPerView: 1.4,
            spaceBetween: 20,
        },
        640: {
            slidesPerView: 2.1,
            spaceBetween: 20,
        },
        768: {
            slidesPerView: 2.01,
            spaceBetween: 25,
        },
        992: {
            slidesPerView: 3,
            spaceBetween: 30,
        },
        1120: {
            slidesPerView: 4,
            spaceBetween: 30,
        }
    }
});

const gallery_slider = new Swiper('.js_gallery_slider', {
    slidesPerView: 1,
    spaceBetween: 15,
    slidesPerView: "auto",
    centeredSlides: true,
    loop: true,
    pagination: {
        el: ".swiper-pagination",
    },
    pagination: {
        dynamicBullets: true,
        el: ".swiper-pagination",
    },
    navigation: {
        nextEl: '.js_gallery_btn_right',
        prevEl: '.js_gallery_btn_left',
    },
    breakpoints: {
        640: {
            slidesPerView: 2,
            spaceBetween: 20,
        },
        992: {
            slidesPerView: 2.5,
            spaceBetween: 30,
        }
    }
});

const gallery_slider_nav = new Swiper('.js_gallery_slider-t2_nav', {
    slidesPerView: 3,
    spaceBetween: 10,
    loop: false,
    freeMode: true,
    watchSlidesProgress: true,
    breakpoints: {
        640: {
            slidesPerView: 4,
            spaceBetween: 20,
        },
        992: {
            slidesPerView: 4,
            spaceBetween: 30,
        }
    }
});

const gallery_slider_t2 = new Swiper('.js_gallery_slider-t2', {
    slidesPerView: 1,
    spaceBetween: 15,
    loop: true,
    dynamicBullets: true,
    thumbs: {
        swiper: gallery_slider_nav,
    },
    navigation: {
        nextEl: '.js_gallery_btn_right',
        prevEl: '.js_gallery_btn_left',
    },
    pagination: {
        el: ".swiper-pagination",
    }
});

const reviews_slider = new Swiper('.js_reviews_slider', {
    slidesPerView: 1,
    spaceBetween: 15,
    slidesPerView: "auto",
    centeredSlides: false,
    loop: true,
    pagination: {
        el: ".swiper-pagination",
    },
    navigation: {
        nextEl: '.js_reviews_btn_right',
        prevEl: '.js_reviews_btn_left',
    },
    breakpoints: {
        640: {
            slidesPerView: 2,
            spaceBetween: 20,
            centeredSlides: false,
        },
        992: {
            slidesPerView: 3,
            spaceBetween: 30,
            centeredSlides: true,
        }
    }
});

const reviews_slider_t3 = new Swiper('.js_reviews_slider-t3', {
    slidesPerView: 1,
    spaceBetween: 15,
    slidesPerView: "auto",
    loop: true,
    pagination: {
        el: ".swiper-pagination",
    },
    navigation: {
        nextEl: '.js_reviews_btn_right',
        prevEl: '.js_reviews_btn_left',
    },
    breakpoints: {
        640: {
            slidesPerView: 2,
            spaceBetween: 20,
        },
        992: {
            slidesPerView: 2,
            spaceBetween: 30,
        }
    }
});

const reviews_slider_t2 = new Swiper('.js_reviews_slider-t2', {
    slidesPerView: 1,
    spaceBetween: 15,
    slidesPerView: "auto",
    loop: true,
    pagination: {
        el: ".swiper-pagination",
    },
    navigation: {
        nextEl: '.js_reviews_btn_right',
        prevEl: '.js_reviews_btn_left',
    },
});

const partners_slider_t2 = new Swiper('.js_partners_slider-t2', {
    slidesPerView: 1,
    spaceBetween: 15,
    loop: false,
    pagination: {
        el: ".swiper-pagination",
    },
    navigation: {
        nextEl: '.js_partners_btn_right',
        prevEl: '.js_partners_btn_left',
    },
    breakpoints: {
        480: {
            slidesPerView: 2,
            spaceBetween: 20,
        },
        993: {
            slidesPerView: 3,
        },
        1100: {
            slidesPerView: 4,
            spaceBetween: 30,
        }
    }
});

const steps_slider = new Swiper('.js_steps_slider', {
    slidesPerView: 1,
    spaceBetween: 10,
    loop: false,
    pagination: {
        el: ".swiper-pagination",
    },
    navigation: {
        nextEl: '.js_steps_btn_right',
        prevEl: '.js_steps_btn_left',
    },
    breakpoints: {
        510: {
            slidesPerView: 1.4,
            spaceBetween: 20,
        },
        640: {
            slidesPerView: 2.1,
            spaceBetween: 20,
        },
        768: {
            slidesPerView: 2.01,
            spaceBetween: 25,
        },
        992: {
            slidesPerView: 4,
            spaceBetween: 30,
        }
    }
});

// var mediaPhone = 576;
// var mediaTablet = 992;

var advantages_slider = null;
var partners_slider = null;

function sliderInit() {
    if (!advantages_slider) {
        advantages_slider = new Swiper('.js_advantages_slider', {
            slidesPerView: 1,
            spaceBetween: 0,
            loop: true,
            pagination: {
                el: ".swiper-pagination",
            },
            breakpoints: {
                640: {
                    slidesPerView: 2,
                    spaceBetween: 40,
                }
            }
        });
    }
}

function sliderPartnersInit() {
    if (!partners_slider) {
        partners_slider = new Swiper('.js_partners_slider', {
            slidesPerView: 3,
            spaceBetween: 25,
            grid: {
                fill: 'row',
                rows: 2,
            },
            loop: false,
            pagination: {
                el: ".swiper-pagination",
            },
        });
    }
}

// function sliderDestroy() {
//     if (advantages_slider) {
//         advantages_slider.destroy();
//         advantages_slider = null;
//     }

//     if (partners_slider) {
//         partners_slider.destroy();
//         partners_slider = null;
//     }
// }

// $(window).on('load resize', function () {
//     // Берём текущую ширину экрана
//     var windowWidth = $(this).innerWidth();

//     // Если ширина экрана меньше или равна mediaPhone(510)
//     if (windowWidth <= mediaTablet) {
//         // Инициализировать слайдер если он ещё не был инициализирован
//         sliderInit()
//     } else {
//         // Уничтожить слайдер если он был инициализирован
//         sliderDestroy()
//     }

//     if (windowWidth <= mediaPhone) {
//         sliderPartnersInit()
//     } else {
//         sliderDestroy()
//     }
// });