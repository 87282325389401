//скрипт который подтягивает значения из карточки товара в которой была вызвана форма
const getDataOrderForm = function() {
    const orderPopup = document.getElementById('popup_order');
    
    let orderPopupTitle = orderPopup.querySelector('.modal__title');
    let orderPopupImg = orderPopup.querySelector('.modal__img');
    let orderPopupPrice = orderPopup.querySelector('.modal__cost');
    let orderPopupOldPrice = orderPopup.querySelector('.modal__old-cost');
    let orderPopupInputProduct = orderPopup.querySelector('input[name="product"]');
    let orderPopupInputCost = orderPopup.querySelector('input[name="cost"]');
    let orderPopupInputImg = orderPopup.querySelector('input[name="image"]');
    
    const clearPopup = function() {
        orderPopupTitle.textContent = '';
        orderPopupImg.src = '';
        orderPopupPrice.textContent = '';
        orderPopupOldPrice.textContent = '';
        orderPopupInputProduct.value = '';
        orderPopupInputCost.value = '';
        orderPopupInputImg.value = '';
    }
    
    document.addEventListener('click', function(event) {
        let target = event.target;
        
        if (target.closest('.js-order-card')) {
            
            clearPopup();
            
            let card = target.closest('.js-order-card');
            
            let cardTitle = card.querySelector('.js-order-title');
            let cardImg = card.querySelector('.js-order-img');
            let cardPrice = card.querySelector('.js-order-price');
            let cardPriceCurrency = card.querySelector('.js-order-currency');
            let cardOldPrice = card.querySelector('.js-order-oldPrice');
            
            orderPopupTitle.textContent = cardTitle.textContent;
            
            if (cardPriceCurrency) {
               orderPopupPrice.textContent = cardPrice.textContent + ' ' + cardPriceCurrency.textContent; 
            } else {
                orderPopupPrice.textContent = cardPrice.textContent;
            }
            
            if (cardOldPrice) {
                if (cardPriceCurrency) {
                    orderPopupOldPrice.textContent = cardOldPrice.textContent + ' ' + cardPriceCurrency.textContent;
                } else {
                    orderPopupOldPrice.textContent = cardOldPrice.textContent;  
                }
            }
            
            orderPopupImg.src = cardImg.src;
            orderPopupInputProduct.value = cardTitle.textContent;
            orderPopupInputCost.value = cardPrice.textContent;
            orderPopupInputImg.value = cardImg.src;
        }
    });
};

//скрипт который подтягивает значения из карточки услуги в которой была вызвана форма
const getDataServiceForm = function() {
    const servicePopup = document.getElementById('popup_service');
    
    let servicePopupTitle = servicePopup.querySelector('.modal__title');
    let servicePopupCost = servicePopup.querySelector('.modal__desc.cost');
    let servicePopupInputService = servicePopup.querySelector('input[name="service"]');
    let servicePopupInputCost = servicePopup.querySelector('input[name="cost"]');
    
    const clearPopup = function() {
        servicePopupTitle.textContent = '';
        servicePopupCost.textContent = '';
        servicePopupInputService.value = '';
        servicePopupInputCost.value = '';
    }
    
    document.addEventListener('click', function(event) {
        let target = event.target;
        
        if (target.closest('.js-service-card')) {
            
            clearPopup();
            
            let card = target.closest('.js-service-card');
            
            let cardTitle = card.querySelector('.js-service-title');
            let cardPrice = card.querySelector('.js-service-price');
            
            servicePopupTitle.textContent = cardTitle.textContent;
            
            if (cardPrice) {
                servicePopupCost.textContent = cardPrice.textContent;
                servicePopupInputCost.value = cardPrice.textContent;
            }
            
            servicePopupInputService.value = cardTitle.textContent;
        }
    });
};

//скрипт который подтягивает значения из карточки отзыва в которой была вызвана форма
const getDataReviewForm = function() {
    const reviewPopup = document.getElementById('popup_reviews');
    
    let reviewName = reviewPopup.querySelector('.block-review__name');
    let reviewStatus = reviewPopup.querySelector('.block-review__desc');
    let reviewImg = reviewPopup.querySelector('.block-review__img');
    let reviewContent = reviewPopup.querySelector('.block-review__text');
    let reviewRating = reviewPopup.querySelector('.star-rating__val');
    let reviewDate = reviewPopup.querySelector('.block-review__date');
    
    const clearPopup = function() {
        reviewName.textContent = '';
        reviewStatus.textContent = '';
        reviewContent.textContent = '';
        reviewContent.textContent = '';
        reviewImg.src = '';
        reviewRating.style.width = '';
    }
    
    document.addEventListener('click', function(event) {
        let target = event.target;
        
        if (target.closest('.js-review-card')) {
            
            clearPopup();
            
            let card = target.closest('.js-review-card');
            
            let cardName = card.querySelector('.js-review-name');
            let cardStatus = card.querySelector('.js-review-status');
            let cardImg = card.querySelector('.js-review-img');
            let cardContent = card.querySelector('.js-review-content');
            let cardRating = card.querySelector('.js-review-rating');
            let cardDate = card.querySelector('.js-review-date');

            reviewName.textContent = cardName.textContent;
            reviewStatus.textContent = cardStatus.textContent;
            reviewImg.src = cardImg.src;
            reviewContent.insertAdjacentHTML('beforeend', cardContent.innerHTML);
            reviewRating.style.width = cardRating.style.width;
            reviewDate.textContent = cardDate.textContent;
        }
    });
};

//скрипт который подтягивает значения из карточки отзыва с изображением в которой была вызвана форма
const getDataReviewAltForm = function() {
    const reviewPopup = document.getElementById('popup_reviews_v2');
    
    let reviewImg = reviewPopup.querySelector('.modal__review-img');
    
    document.addEventListener('click', function(event) {
        let target = event.target;
        
        if (target.closest('.js-review-img')) {
            
            reviewImg.src = '';
            
            let img = target.closest('.js-review-img');
            
            reviewImg.src = img.src;
        }
    });
};

//скрипт который подтягивает значения из кнопки главного баннера по которой прошел клик в форму
const getDataMainForm = function() {
    const mainPopup = document.getElementById('popup_main');
    
    let popupTitle = mainPopup.querySelector('.modal__title');
    
    document.addEventListener('click', function(event) {
        let target = event.target;
        
        if (target.closest('.js-button-main')) {
            
            popupTitle.textContent = '';
            
            popupTitle.textContent = target.closest('.js-button-main').textContent;
        }
    });
};

//скрипт получает имя файла который хочет отправить пользователь
const checkFileName = function() {
    document.addEventListener('change', function(event) {
        let target = event.target;
        
        if (target.closest('.input-attachment__input')) {
            let fileName = target.files[0].name;
            
            target.closest('.input-attachment').querySelector('.input-attachment__subtext').textContent = fileName;
        }
    });
};

//функция подстановки текста оценки в форме отзыва. Данные подтягиваются на основе radio
const reviewRatingText = function() {
    document.addEventListener('change', function(event) {
        let target = event.target;
      
        if (target.closest('input[name=rating]')) {
          
            let ratingText = target.closest('.rating-area').querySelector('.rating-text');
            
            switch (target.closest('input[name=rating]').value) {
                case '1':
                    ratingText.textContent = ' — Очень плохо';
                    break;
                case '2':
                    ratingText.textContent = ' — Плохо';
                    break;
                case '3':
                    ratingText.textContent = ' — Нормально';
                    break;
                case '4':
                    ratingText.textContent = ' — Хорошо';
                    break;
                case '5':
                    ratingText.textContent = ' — Отлично';
                    break;
                default:
                    ratingText.textContent = 'Что-то пошло не так...';
            }
        }
    });
    
    //возвращение текста с описанием рейтинга к дефолтному состоянию после отправки формы отзыва
    $(document).on('af_complete', function(event, response) {
        let form = response.form;
        
        let ratingText = document.querySelector('.rating-text');
        let fileText = document.querySelector('.input-attachment__subtext');
        
        if (form.attr('id') == 'sendReview' && response.success) {
            ratingText.textContent = ' — Хорошо';
            fileText.textContent = 'JPEG, PNG';
        }
    });
};

getDataOrderForm();
getDataServiceForm();
getDataReviewForm();
getDataReviewAltForm();
getDataMainForm();

checkFileName();

reviewRatingText();