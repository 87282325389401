const toogleModal = () => {

    document.addEventListener("click", (event) => {
        let target = event.target;
        
        if (target.closest('[data-popup]')) {
            event.preventDefault();
          
            document.querySelector(`${target.closest("[data-popup]").dataset.popup}`).classList.add("active");
            document.body.classList.add("lock");
        }
    
        if (target.closest(".modal.active") && !target.closest('.modal__container') && !target.closest('.modal__review') || target.closest('.js_close_modal')) {
            
            target.closest(".modal.active").classList.add('out');
            
            setTimeout(function() {
                target.closest(".modal.active").classList.remove('out');
                target.closest(".modal.active").classList.remove("active");
                document.body.classList.remove("lock");
            }, 500);
        }
    });

    document.addEventListener("keydown", (event) => {
        if (event.keyCode === 27 && document.querySelector('.modal.active')) {
            
            document.querySelector(".modal.active").classList.add('out');
            
            setTimeout(function() {
                document.querySelector(".modal.active").classList.remove('out');
                document.querySelector('.modal.active').classList.remove("active");
                document.body.classList.remove("lock");
            }, 500);
        }
    });
};

toogleModal();