const hideMenu = function() {
    const menu = document.querySelector('.header:not(.header--static)');
    if (window.innerWidth > 768 && menu) {
        
        let scrolltop = pageYOffset;
        
        window.addEventListener('scroll', function() {
            
            if (window.pageYOffset >= 756 && pageYOffset > scrolltop) {
                menu.classList.add('hidden');
            } else if (window.pageYOffset < 756 || pageYOffset < scrolltop) {
                menu.classList.remove('hidden');
            }
            
            scrolltop = pageYOffset;
        });
        
        menu.addEventListener("mouseover", (event) => {
            let target = event.target;
            if (window.pageYOffset >= 756 && target.closest('header')) {
                menu.classList.remove('hidden');
            }
        });
    }
}

const openSideMenu = function(itemsClass, elemClass) {
   let menuItems = [...document.querySelectorAll(itemsClass)];

    menuItems.forEach(function(item) {
    
       item.addEventListener("mouseover", () => {
            if (item.querySelector(elemClass)) {
                item.querySelector(elemClass).classList.add('active');
            }
        });
        
        item.addEventListener("mouseout", () => {
          
            if (item.querySelector(elemClass)) {
                item.querySelector(elemClass).classList.remove('active');
            }
        });
    }); 
};

hideMenu();

openSideMenu('.header-nav__item', '.header-nav__submenu');
openSideMenu('.submenu__item', '.submenu');
openSideMenu('.time-work.dropdown', '.time-work__submenu');