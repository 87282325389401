$(".dropdown .dropdown__input_container").on('click', function () {
    var dropdown = $(this).parent('.dropdown');
    if ($(dropdown).hasClass('active')) {
        $(dropdown).removeClass('active');
    } else {
        $(dropdown).addClass('active');
    }
});

$(document).mouseup(function (e){ // событие клика по веб-документу
    var div = $(".dropdown"); // тут указываем ID элемента
    if (!div.is(e.target) // если клик был не по нашему блоку
    && div.has(e.target).length === 0) { // и не по его дочерним элементам
        $(div).removeClass('active');
    }
});

let inputReplace = function() {
    
    
    document.addEventListener('click', function(event) {
        let target = event.target;
        
        if (target.closest('.dropdown__item')) {
            let inputWrapper = target.closest('.label-group');
            let input = inputWrapper.querySelector('input');
            let inputFlag = inputWrapper.querySelector('.dropdown__flag').querySelector('img');
            
            let dropdownItem = target.closest('.dropdown__item');
            let dropdownItemFlag = dropdownItem.querySelector('.ico').querySelector('img');
            let dropdownItemCode = dropdownItem.querySelector('.code');
            
            
            inputFlag.src = dropdownItemFlag.src;
            
            input.value = '';
            input.dataset.phonePattern = dropdownItemCode.textContent.trim() + ' ' + dropdownItem.dataset.mask;
            input.placeholder = dropdownItemCode.textContent.trim();
        }
    });
};

inputReplace();