let adaptiveTable = function() {
    
    let tables = document.querySelectorAll('table');

    const removeStyle = function(elem) {
        elem.style.height = null;
        elem.style.width = null;
    };
    
    tables.forEach(function(item) {
        
        if (item.querySelector('colgroup')) {
            item.querySelector('colgroup').remove();
        }
           
        let trFirst = item.querySelector('tr:first-child');
        let tdFirst = [...trFirst.querySelectorAll('th')].length > 0 ? [...trFirst.querySelectorAll('th')] : [...trFirst.querySelectorAll('td')];
        let tr = item.querySelectorAll('tr:not(:first-child)');
        
        let headValues = [];
        
        removeStyle(item);
        removeStyle(trFirst);
        
        tdFirst.forEach(function(elem) {
            headValues.push(elem.textContent);
            removeStyle(elem);
        });
        
        tr.forEach(function(elem) {
            let td = [...elem.querySelectorAll('td')];
            
            removeStyle(elem);
            
            td.forEach(function(tdElem, i) {
                
                removeStyle(td[i]);
                
                let content = td[i].innerHTML;
                td[i].textContent = '';
                td[i].insertAdjacentHTML('beforeend', `<div class="table-param">${content}</div>`);
                
                let adaptiveTitle = document.createElement('span');
                adaptiveTitle.classList = 'adaptive-title';
                adaptiveTitle.textContent = headValues[i];
                td[i].prepend(adaptiveTitle);
            });
        });
    });
};

adaptiveTable();