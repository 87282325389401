let lazyLoadInstance = new LazyLoad({
  elements_selector: ".lazy-load",
});

const lazyLoadSlider = function() {

    if(!!window.IntersectionObserver){
        // устанавливаем настройки
        const options = {
            // родитель целевого элемента - область просмотра
            root: null,
            // без отступов
            rootMargin: '0px',
            // процент пересечения - половина изображения
            threshold: 0.2,
        }
        
        // создаем наблюдатель
        const observer = new IntersectionObserver((entries, observer) => {
            // для каждой записи-целевого элемента
            entries.forEach(entry => {
                // если элемент является наблюдаемым
                if (entry.isIntersecting) {
                    
                    lazyLoadInstance.update();
                    // прекращаем наблюдение
                    observer.unobserve(entry.target);
                }
            });
        }, options);
        
        // с помощью цикла следим за всеми img на странице
        const arr = document.querySelectorAll('.lazy-load:not(.loaded)');
        
        arr.forEach(i => {
            observer.observe(i);
            
        });
    } else {
        const arr = document.querySelectorAll('.lazy-load');
        arr.forEach(i => {
            i.src = i.dataset.src;
            i.classList.remove('lazy-load');
        });
    }
}

const lazyLoadAjax = function(){
    let observerAjax = new MutationObserver(mutations => {
    
        for(let mutation of mutations) {
        
            for(let node of mutation.addedNodes) {
              
                if (!(node instanceof HTMLElement)) continue;
              
                if (node.querySelector('.lazy-load')) {
                    lazyLoadInstance.update();
                }
                if (node.classList.contains('anim')) {
                    node.classList.add('done');
                }
            }
        }
    
    });
    
    let ajaxContainer = document.querySelectorAll('.rows');
    
    ajaxContainer.forEach(i => {
      observerAjax.observe(i, {childList: true, subtree: true});
    });
}

lazyLoadSlider();
lazyLoadAjax();